
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/advertising'

@Component({
  name: 'AdvertisingDetail'
})
export default class extends Vue {
  private advertiseId = ''
  private detailInfo: DetailInfo | null = null

  created () {
    this.advertiseId = this.$route.params.advertiseId
    this.advertiseId && this.getDetail()
  }

  // 获取详情
  getDetail () {
    this.$axios
      .get(this.$apis.advertising.selectAdvertiseByAdvertiseId, {
        advertiseId: this.advertiseId
      })
      .then((res) => {
        console.log('res: ', res)
        this.detailInfo = res || {}
      })
  }
}
